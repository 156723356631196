import React from "react";
 
class Home extends React.Component
{
 
  render()
  { 
    
    //data-date="2021-11-10" data-time="19:00"

    const getImagePath = (name) => {
      return require(`../assets/images/${name}`).default
    }

    return (
    
        <div>
            
            <section className="banner-section">

              <div className="container">

                <div className="row justify-content-center">

                  <div className="col-md-5">

                    <div className="banner-text text-center">

                      <h2>Welcome to <br /> <span className="red-text">Bored</span> <span className="yellow-text">Babes</span></h2>

                      <p>10,010 randomly generated ape babes taking over the metaverse as ERC-721 tokens and hosted securely by IPFS  </p>

                    </div>

                  </div>

                  <div className="col-md-6">

                    <div className="banner-img"> 
                      <img src={getImagePath('bored-babe-gif3.gif')} alt="" title="" />
 
                    </div>

                  </div>

                </div>

                {/*<h4 className="launch-date"> Presale Begins 11/10/21 <span className="red-text"> @ </span> 7PM UTC </h4>*/}
                <h4 className="launch-date"> Public Sale 11/15/21 <span className="red-text"> @ </span> 7PM UTC </h4>
                <h4 className='countdown launch-date' data-date="2021-11-15" data-time="19:00"></h4>
              </div>

            </section>

            <section className="divider">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <hr />
                  </div>
                </div>
              </div>
            </section>

            <section className="discord-section" id="traits">

              <div className="container">

                <div className="row align-items-center">

                  <div className="col-md-5">

                    <div className="discord-text text-center">

                    <h2>No Two Are <span className="red-text">The</span> <span className="yellow-text"> Same </span></h2>

                    <p>Every Babe Minted Will Be Unique!</p>

                    <a href="https://discord.com/invite/BoredBabesNft" target="_blank">Join Discord </a>

                    </div>

                  </div>

                  <div className="col-md-7"> 
                    <div className="design-box">
                      <table className="table table-bordered">
                      <tbody>
                      <tr>
                        <td> 
                          <div className="img-box"><img alt="" src={getImagePath('design-1.png')} /></div> 
                          <div className="text-box">  12 <br />Background<br /> Colors</div> 
                        </td>
                        <td>
                          <div className="text-box"> 9 <br /> Sunglasses</div>  
                          <div className="img-box"><img alt="" src={getImagePath('design-2.png')} /></div> 
                        </td>
                      </tr>
                      <tr>
                        <td> 
                          <div className="img-box"><img alt="" src={getImagePath('design-3.png')} /></div> 
                          <div className="text-box">  17 <br />Skin Tones<br /> </div> 
                        </td>
                        <td>
                          <div className="text-box"> 13 <br />Earrings</div> 
                          <div className="img-box"><img alt="" src={getImagePath('design-4.png')} /></div> 
                        </td>
                      </tr>
                      <tr>
                        <td> 
                          <div className="img-box"><img alt="" src={getImagePath('design-5.png')} /></div> 
                          <div className="text-box">  20 <br /> Outfits</div> 
                        </td>
                        <td>
                          <div className="text-box">11 <br />Necklaces</div> 
                          <div className="img-box"><img alt="" src={getImagePath('design-6.png')} /></div> 
                        </td>
                      </tr>
                      <tr>
                        <td> 
                          <div className="img-box"><img alt="" src={getImagePath('design-7.png')} /></div> 
                          <div className="text-box">  17 <br />Hairstyles</div> 
                        </td>
                        <td>
                          <div className="text-box"> 7 <br />Lip Colors</div> 
                          <div className="img-box"><img alt="" src={getImagePath('design-8.png')} /></div> 
                        </td>
                      </tr>
                      <tr>
                        <td> 
                          <div className="img-box"><img alt="" src={getImagePath('design-9.png')} /></div> 
                          <div className="text-box">  10 <br />Hair Colors</div> 
                        </td>
                        <td>
                          <div className="text-box"> 7 <br />Eye Colors</div> 
                          <div className="img-box"><img alt="" src={getImagePath('design-10.png')} /></div> 
                        </td>
                      </tr>
                      <tr>
                        <td> 
                          <div className="img-box"><img alt="" src={getImagePath('design-11.png')} /></div> 
                          <div className="text-box">  10 <br />Facial Expressions</div> 
                        </td>
                        <td>
                          <div className="text-box"> 10 <br />Eyeshadow Colors</div> 
                          <div className="img-box"><img alt="" src={getImagePath('design-12.png')} /></div> 
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="2" className="last-td"> 
                          
                          <div className="text-box">  6 <br />Super Rare Traits</div> 
                          <div className="img-box"><img alt="" src={getImagePath('design-13.png')} /></div>    
                        </td>
                        
                      </tr>
                      </tbody>
                      </table>
                    </div>

                  </div>

                </div>

              </div>

            </section>

            <section className="divider">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <hr />
                  </div>
                </div>
              </div>
            </section>



            <section className="feature-section" id="ownerperks">

              <div className="container">

                <div className="row">

                  <div className="col-md-5 text-center">

                    <h2><span className="red-text">Perks</span> of <span className="yellow-text">Ownership</span></h2>

                    <div className="feature-img">

                      <div className="img-box">

                        <img src={getImagePath('round-circel.gif')} alt="" title="" className="circel-img" />

                        <img src={getImagePath('s1.webp')} alt="" title="" className="design-img" />

                      </div>

                    </div>

                  </div>

                  <div className="col-md-7">

                    <div className="feature-list">

                      <div className="row">

                        <div className="col-md-2 col-2">

                          <div className="icon-box">

                            <img alt="" src={getImagePath('arrow.png')} />

                          </div>

                        </div>

                        <div className="col-md-10 col-10">

                          <div className="feature-detail">

                            <h4>Bored Babes holders will get airdrop of special NFT collection currently in production</h4>

                          </div>

                        </div>

                      </div>

                    </div>

                    <div className="feature-list">

                      <div className="row">

                        <div className="col-md-2 col-2">

                          <div className="icon-box">

                            <img alt="" src={getImagePath('arrow.png')} />

                          </div>

                        </div>

                        <div className="col-md-10 col-10">

                          <div className="feature-detail">

                            <h4> The Community Wallet will be used to buy popular NFT’s to give to Bored Babe holders</h4>

                          </div>

                        </div>

                      </div>

                    </div>

                    <div className="feature-list">

                      <div className="row">

                        <div className="col-md-2 col-2">

                          <div className="icon-box">

                            <img alt="" src={getImagePath('arrow.png')} />

                          </div>

                        </div>

                        <div className="col-md-10 col-10">

                          <div className="feature-detail">

                            <h4>Bored Babe holders will have access to the REKT Reimbursement Fund</h4>

                          </div>

                        </div>

                      </div>

                    </div>

                    <div className="feature-list">

                      <div className="row">

                        <div className="col-md-2 col-2">

                          <div className="icon-box">

                            <img alt="" src={getImagePath('arrow.png')} />

                          </div>

                        </div>

                        <div className="col-md-10 col-10">

                          <div className="feature-detail">

                            <h4> All Bored Babe holders will have a vote to determine Business Grant Recipients </h4>

                          </div>

                        </div>

                      </div>

                    </div>

                    <div className="feature-list">

                      <div className="row">

                        <div className="col-md-2 col-2">

                          <div className="icon-box">

                            <img alt="" src={getImagePath('arrow.png')} />

                          </div>

                        </div>

                        <div className="col-md-10 col-10">

                          <div className="feature-detail">

                            <h4>Discounted limited edition merchandise  </h4>

                          </div>

                        </div>

                      </div>

                    </div>

                    <div className="feature-list">

                      <div className="row">

                        <div className="col-md-2 col-2">

                          <div className="icon-box">

                            <img alt="" src={getImagePath('arrow.png')} />

                          </div>

                        </div>

                        <div className="col-md-10 col-10">

                          <div className="feature-detail">

                            <h4> Monthly Bored Babe Giveaways and Contest  </h4>

                          </div>

                        </div>

                      </div>

                    </div>

                    <div className="feature-list mb-0">

                      <div className="row">

                        <div className="col-md-2 col-2">

                          <div className="icon-box">

                            <img alt="" src={getImagePath('arrow.png')} />

                          </div>

                        </div>

                        <div className="col-md-10 col-10">

                          <div className="feature-detail">

                            <h4> Revenue generation from Sandbox land plot distributed in ETH </h4>

                          </div>

                        </div>

                      </div>

                    </div>

                  </div>

                </div>

              </div>

            </section>


            <section className="divider">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <hr />
                  </div>
                </div>
              </div>
            </section>

            <section id="roadmap" className="section roadmap-section">

                <div className="container">
 
                    <h2 className="text-center"> What The <span className="red-text"> Future</span> <span className="yellow-text"> Holds</span> </h2>

                    <div className="timeline">

                        <div className="timeline-card left t-10">

                            <div className="content">

                                <h3>First Airdrop</h3>

                                <div className="timeline-content">

                                    <p>Complete airdrop of Bored Babes NFT Giveaway</p>

                                </div>

                            </div>

                        </div>

                        <div className="timeline-card right t-20">

                            <div className="content">

                                <h3>Community Wallet</h3>

                                <div className="timeline-content">

                                    <p>Establish and fund the Bored Babes community wallet</p>

                                </div>

                            </div>

                        </div>

                        <div className="timeline-card left t-30">

                            <div className="content">

                                <h3>REKT Fund</h3>

                                <div className="timeline-content">

                                    <p>Establish and fund the REKT Reimbursement Fund for Bored Babe Holders</p>

                                </div>

                            </div>

                        </div>

                        <div className="timeline-card right t-40">

                            <div className="content">

                                <h3>Business Grants</h3>

                                <div className="timeline-content">

                                    <p>Establish the Business Grant Fund and solicit for grant recipients</p>

                                </div>

                            </div>

                        </div>

                        <div className="timeline-card left t-50">

                            <div className="content">

                                <h3>1st Community Wallet Purchase</h3>

                                <div className="timeline-content">

                                    <p> Purchase BAYC & WoW NFT's and select Platinum OB recipient </p>

                                </div>

                            </div>

                        </div>

                        <div className="timeline-card right t-60">

                            <div className="content">

                                <h3>Merchandise</h3>

                                <div className="timeline-content">

                                    <p>Create limited edition Bored Babe merchandise and setup online store</p>

                                </div>

                            </div>

                        </div>

                        <div className="timeline-card left t-70">

                            <div className="content">

                                <h3>Theme Song</h3>

                                <div className="timeline-content">

                                    <p>Record and release Bored Babes theme song and music video</p>

                                </div>

                            </div>

                        </div>

                        <div className="timeline-card right t-80">

                            <div className="content">

                                <h3>Land Purchase</h3>

                                <div className="timeline-content">

                                    <p>Shop for Sandbox land plot </p>

                                </div>

                            </div>

                        </div>

                        <div className="timeline-card left t-90">

                            <div className="content">

                                <h3>1st Reimbursement</h3>

                                <div className="timeline-content">

                                    <p>Give first REKT Reimbursement to Bored Babe holder</p>

                                </div>

                            </div>

                        </div>

                        <div className="timeline-card right t-100">

                            <div className="content">

                                <h3>1st Business Grant  </h3>

                                <div className="timeline-content">

                                    <p>Community vote on first recipient of Bored Babes business grant</p>

                                </div>

                            </div>

                        </div>

                    </div>

                </div>

            </section>

            <section className="divider">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <hr />
                  </div>
                </div>
              </div>
            </section>

            <section className="faq-section" id="faq">

              <div className="container">

                <h2 className="heading"> <span className="yellow-text">Have</span> More <span className="red-text">Questions</span> <img alt="" className="qts-img" src={getImagePath('qts.png')} /> </h2>

                <div className="accordion" id="accordionExample">

              <div className="accordion-item">

                <h2 className="accordion-header" id="headingOne">

                  <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">

                     What inspired the Bored Babes NFT Collection?

                  </button>

                </h2>

                <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">

                  <div className="accordion-body">

                    Bored Babes was inspired by the BAYC and WOW NFT Collections. 

                  </div>

                </div>

              </div>

              <div className="accordion-item">

                <h2 className="accordion-header" id="headingTwo">

                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">

                    Who created the Bored Babes Collection?

                  </button>

                </h2>

                <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">

                  <div className="accordion-body">

                    Bored Babes was founded by a mother and daughter who loved the nft space and wanted to create their own. The artwork was done by a talented artist named Nikola Miranovic.

                  </div>

                </div>

              </div>

              <div className="accordion-item">

                <h2 className="accordion-header" id="headingThree">

                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">

                     What is the REKT Reimbursement Fund?

                  </button>

                </h2>

                <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">

                  <div className="accordion-body">

                    This fund is for Bored Babe holders who need reimbursement for funds lost while investing in other NFT projects

                  </div>

                </div>

              </div>



            <div className="accordion-item">

                <h2 className="accordion-header" id="headingfour">

                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefour" aria-expanded="false" aria-controls="collapsefour">

                     When will the first business grant be given?

                  </button>

                </h2>

                <div id="collapsefour" className="accordion-collapse collapse" aria-labelledby="headingfour" data-bs-parent="#accordionExample">

                  <div className="accordion-body">

                    The first grant will be given in the first quarter of 2022.

                  </div>

                </div>

              </div>

            <div className="accordion-item">

                <h2 className="accordion-header" id="headingfive">

                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefive" aria-expanded="false" aria-controls="collapsefive">

                     Who is eligible for business grants?

                  </button>

                </h2>

                <div id="collapsefive" className="accordion-collapse collapse" aria-labelledby="headingfive" data-bs-parent="#accordionExample">

                  <div className="accordion-body">

                    Women owned and led businesses will be eligible to apply for business grant.

                  </div>

                </div>

              </div>



              <div className="accordion-item">

                <h2 className="accordion-header" id="headingsix">

                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsesix" aria-expanded="false" aria-controls="collapsesix">

                     When will special airdrop take place?

                  </button>

                </h2>

                <div id="collapsesix" className="accordion-collapse collapse" aria-labelledby="collapsesix" data-bs-parent="#accordionExample">

                  <div className="accordion-body">

                    We have a special collection in production now and will be airdropped to Bored Babe holders in the first quarter of 2022. Updates will be given via Twitter and Discord.

                  </div>

                </div>

              </div>



              <div className="accordion-item">

                <h2 className="accordion-header" id="headingseven">

                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseseven" aria-expanded="false" aria-controls="collapseseven">

                    How much to mint a Bored Babe?

                  </button>

                </h2>

                <div id="collapseseven" className="accordion-collapse collapse" aria-labelledby="collapseseven" data-bs-parent="#accordionExample">

                  <div className="accordion-body">

                    Minting cost will be 0.07 + gas fees.

                  </div>

                </div>

              </div>



                <div className="accordion-item">

                <h2 className="accordion-header" id="headingeight">

                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseeight" aria-expanded="false" aria-controls="collapseeight">

                    What will community wallet be used for?

                  </button>

                </h2>

                <div id="collapseeight" className="accordion-collapse collapse" aria-labelledby="collapseeight" data-bs-parent="#accordionExample">

                  <div className="accordion-body">

                    Community wallet will be used to buy popular NFT’s that will then be distributed to Bored Babe holders. The first two purchases will be BAYC and WOW. After, the community will vote on which projects to invest in and randomly chosen addresses with at least 1 Bored Babe will receive the NFT. 

                  </div>

                </div>

              </div>



                <div className="accordion-item mb-0">

                <h2 className="accordion-header" id="headingnine">

                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsenine" aria-expanded="false" aria-controls="collapsenine">

                    Do you have any other plans?

                  </button>

                </h2>

                <div id="collapsenine" className="accordion-collapse collapse" aria-labelledby="collapsenine" data-bs-parent="#accordionExample">

                  <div className="accordion-body">

                    Yes! We are working on special edition merchandise, a Bored Babes theme song, purchasing land in the Sandbox metaverse and partnering with other NFT projects to grow the space. We will also be taking suggestions from our community on how they want to see us expand. 

                  </div>

                </div>

              </div>



            </div>

              </div>

            </section>



            <section className="divider">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <hr />
                  </div>
                </div>
              </div>
            </section>



            <section className="team-section" id="team">

              <div className="container">

                <h2>Team <span className="yellow-text"> Work </span> Makes <br /> <span className="red-text">The Dream Work</span></h2>

            <div className="row">

                  <div className="col-md-3">

                    <div className="team-box">

                      <h4>Tamara Dior</h4>

                      <img alt="" src={getImagePath('team-1.png')} />

                      <p> Founder </p>

                    </div>

                  </div>

                  <div className="col-md-3">

                    <div className="team-box">

                      <h4> Deepak Saini </h4>

                      <img alt="" src={getImagePath('team-2.png')} />

                      <p> Web Developer </p>

                    </div>

                  </div>

                  <div className="col-md-3">

                    <div className="team-box">

                      <h4> Nikola Miranovic  </h4>

                      <img alt="" src={getImagePath('team-3.png')} />

                      <p> Illustrator </p>

                    </div>

                  </div>

                  <div className="col-md-3">

                    <div className="team-box">

                      <h4> Adeel Rana </h4>

                      <img alt="" src={getImagePath('team-4.png')} />

                      <p> Smart Contract Dev</p>

                    </div>

                  </div>

                </div>

              </div>

            </section>

            <section className="as-seen-section" id="seen">
            <div className="container">
              <h2> <span className="red-text">As </span> <span className="yellow-text"> Seen On </span> </h2>
              <div className="row align-items-center">
                <div className="col-lg-4 text-center">
                  <a href="https://nftcalendar.io/event/bored-babes-nft/" target="_blank">
                    <img src={getImagePath('logo-3.webp')} alt="" />
                  </a> 
                </div>
                <div className="col-lg-4 text-center">
                  <a href="https://www.non-fungi.com/drops/bored-babes/" target="_blank">
                    <img src={getImagePath('logo-2.webp')} alt=""/>
                  </a>
                </div>
                <div className="col-lg-4 text-center">
                  <a href="https://nftevening.com/event/bored-babes-nft-drop/" target="_blank">
                    <img src={getImagePath('logo-1.webp')} alt="" />
                  </a>
                </div>

              </div>
            </div>
          </section>

            <section className="divider">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <hr />
                  </div>
                </div>
              </div>
            </section>
            <div id="gallery"><div className="bg"></div></div>

        </div>
      
    )
  }
}
export default Home;