import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter } from "react-router-dom";

import './assets/css/bootstrap.css';
import './assets/css/fontawesome.css'; 
import './index.css';
import './assets/css/countdown.css';

//import $ from 'jquery';
import '../node_modules/bootstrap/dist/js/bootstrap.min.js';
import './assets/js/countdown.js';
 
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <BrowserRouter>
      <App/>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
