import React from "react";
import { Link } from 'react-router-dom';
 
class NotFound extends React.Component
{
 
  render()
  { 
  
    const getImagePath = (name) => {
      return require(`../assets/images/${name}`).default
    }

    return (
    
        <div>  
            <section className="banner-section">
              <div className="page-404-section">
                <h1>404 - Not Found!</h1>
                    <Link to="/">
                    Go Home
                  </Link>
                </div>
            </section>
 
        </div>
      
    )
  }
}
export default NotFound;