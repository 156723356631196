import React from "react";
 

class Footer extends React.Component
{
  render()
  {
    return (
        <section className="footer-section">

          <div className="container">

            <div className="row">

              <div className="col-md-4">

                <div className="social-icon text-center">

                  <a href="https://twitter.com/boredbabesnft" target="_blank"> 

                      <i className="fab fa-twitter"></i> 

                    </a>

                    <a href="https://www.instagram.com/boredbabesnft/" target="_blank">

                      <i className="fab fa-instagram"></i>

                    </a>

                    <a href="https://discord.com/invite/BoredBabesNft" target="_blank" rel="noreferrer">

                      <i className="fab fa-discord"></i>

                    </a>

                    <a href="https://medium.com/@boredbabesnft" target="_blank">

                      <i className="fab fa-medium"></i>

                    </a>

                </div>

              </div>

              <div className="col-md-8">

                <div className="text-box">
                  <p> <a target="_blank" href="https://etherscan.io/address/0x5a297395c0b56d2d2adf2e41452970c065d30c32">Verified Contract</a> <span className="contract-address">0x5A297395C0B56d2D2AdF2e41452970c065D30c32</span></p>
                </div>

              </div>

            </div>



          <div className="row">

            <div className="col-md-4 mt-5">

              <p> © Bored Babes 2021 </p>

            </div>

            <div className="col-md-4 mt-5">

              <p><a href="#">Business Grant Application</a></p>

            </div>

            <div className="col-md-4 mt-5">

              <p><a href="#">REKT Fund Application</a></p>

            </div>

          </div>



          </div>

        </section>
    )
  }
}

export default Footer;