import React from "react";
import logo from '../../assets/images/logo.png';
import { withRouter, Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import jQuery from 'jquery';
import If from '../If.js'

class Header extends React.Component
{ 

  render()
  {

    setInterval(function(){
       jQuery(".countdown").countdown();
    },1000);

    const path_name = this.props.location.pathname
     
    return (
      
          <div className="top-header">
            <nav className="navbar navbar-expand-lg">
              <div className="container-fluid">
                <Link className="navbar-brand" to="/"><img src={logo} alt="" /></Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                  <i className="fas fa-bars"></i>
                </button>
                <div className="collapse navbar-collapse" id="navbarNavDropdown">
                  <ul className="navbar-nav ms-auto me-auto">
                    <li className="nav-item">
                      <HashLink className="nav-link active" to="/#traits">Traits</HashLink>
                    </li>
                    <li className="nav-item">
                      <HashLink className="nav-link" to="/#ownerperks">Owner Perks</HashLink>
                    </li>
                    <li className="nav-item">
                      <HashLink className="nav-link" to="/#roadmap">Roadmap</HashLink>
                    </li>
                    <li className="nav-item">
                      <HashLink className="nav-link" to="/#team">Team</HashLink>
                    </li>
                    <li className="nav-item">
                      <HashLink className="nav-link" to="/#seen">As Seen On</HashLink>
                    </li>
                    <li className="nav-item">
                      <HashLink className="nav-link " to="/#faq">F.A.Q</HashLink>
                    </li>
                  </ul>

                  <div className="right-social-box">
                    <a href="https://twitter.com/boredbabesnft" target="_blank"> 
                      <i className="fab fa-twitter"></i> 
                    </a>
                    <a href="https://www.instagram.com/boredbabesnft/" target="_blank">
                      <i className="fab fa-instagram"></i>
                    </a>

                    <a href="https://discord.com/invite/BoredBabesNft" target="_blank" rel="noreferrer">
                      <i className="fab fa-discord"></i>
                    </a>
                    <a href="https://medium.com/@boredbabesnft" target="_blank">
                      <i className="fab fa-medium"></i>
                    </a>
                  </div>
                  <div className="right-box">

                    <If condition={path_name === '/xavyy19todlopo'} >
                        <a href="#" onClick={this.props.connectMetamask} className="btn-mint">
                        {this.props.account === null ? 'Connect' : this.props.sub_address }</a>
                    </If>

                    <If condition={path_name !== '/xavyy19todlopo'} >
                      <Link className="btn-mint" to="/">mint</Link>
                    </If>     
                
                  </div>

                </div>

              </div>

            </nav>

          </div>
      
    )
  }
}

export default withRouter(Header);
 