import React from "react";
import './App.css';
 
import Header from './components/layouts/Header'; 
import Footer from './components/layouts/Footer';
import Home from './components/Home'; 
//import Mint from './components/Mint';
import Presale from './components/Presale';
import NotFound from './components/NotFound';

import { Route, Switch } from "react-router-dom"; 
import Swal from "sweetalert2";  

class App extends React.Component {

    constructor(props) {
      super(props);
      this.state = { 
        page:'home',
        account: null,
        sub_address:null
      }
  
    }
    
    connectMetamask = async ()  => {
 
      if(window.ethereum){
          if(!this.state.account){
              await window.ethereum.enable();
              this.setAccount()
          }
      }
      else{
          Swal.fire({  
            icon: 'error',  
            title: 'Warning !',  
            text: 'Non-Ethereum browser. Please use metamask!',  
          });
      }
       
    }

    setAccount = async ()  => {
      if(!window.ethereum){
          return false
      }

      if(window.ethereum.isMetaMask === true){
          const account = await window.web3.eth.getAccounts()
          if(account.length){
              let sub_address = account[0].substring(0, 3) + '...'+ account[0].slice(-3)  
              this.setState({account:account[0],sub_address:sub_address})
          }
          
      } 

    }

  
    render() {
 
      return (
        <div className="wrapper">
          <Header 
            page={this.state.page} 
            connectMetamask={this.connectMetamask}
            account={this.state.account}
            sub_address={this.state.sub_address}
            /> 
            
            <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/xavyy19todlopo" >
              <Presale title="Presale"
                setAccount={this.setAccount} 
                account={this.state.account}
                sub_address={this.state.sub_address}
                />
            </Route>
            
            <Route path='*' exact={true} component={NotFound} />

            </Switch>
            <Footer /> 

        </div>
      );
    }
  }

export default App;
